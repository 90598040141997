import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            className="py-12 font-titleFont flex gap-20"
        >
            <div className="w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
                <ResumeCard
                    title="Backend Developer"
                    subTitle="OrigenNetwork - (2022 - Actualidad)"
                    result="Spain"
                    des="OrigenNetwork es una compañía que nace en 2019 con la intención de crear mundos virtuales alcanzables para cualquier persona y poder disfrutar de una manera nueva de los videojuegos."
                    twitter={"https://twitter.com/OrigenNetwork"}
                    tiktok={"https://www.tiktok.com/@origennetwork"}
                />
                <ResumeCard
                    title="Técnico en Instalaciones de Telecomunicaciones"
                    subTitle="Vivacable - (02/2022 - 06/2022)"
                    result="Montijo, Spain"
                    des="Prácticas del ciclo formativo de Técnico en Instalaciones de Telecomunicaciones en la empresa Vivacable."
                    website={"https://www.vivacable.com/"}
                />
                <ResumeCard
                    title="Full Stack Developer"
                    subTitle="Freelancer - (2020 - 2022)"
                    result="Spain"
                    des="Desarrollo de proyectos personales y para terceros. Principalmente proyectos en FiveM y Discord."
                />
            </div>
        </motion.div>
    );
};

export default Experience;
