import React from "react";
import Title from "../layouts/Title";
import {
    projectOne,
    projectTwo,
    projectThree,
    projectFour,
} from "../../assets/index";
import ProjectsCard from "./ProjectsCard";

const Projects = () => {
    return (
        <section
            id="projects"
            className="w-full py-20 border-b-[1px] border-b-black"
        >
            <div className="flex justify-center items-center text-center">
                <Title title="Mi trabajo más destacado" des="Mis proyectos" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
                <ProjectsCard
                    title="OrigenNetwork Helper"
                    des="Bot de ayuda para los servidores de Discord de OrigenNetwork, cuenta con actualizaciones dinámicas de embeds en mensajes, comandos con barra diagonal ( / ), base de datos, estado de los distintos servidores, entre otras funcionalidades."
                    src={projectOne}
                    languages="JavaScript · JSON · NodeJS · DiscordJS"
                />
                <ProjectsCard
                    title="Juego del laberinto"
                    des="Un juego que trata sobre escapar de un laberinto, completamente jugable en la consola de Java, cuenta con un sistema de Mapas, enemigos, objetos, entre otras características."
                    src={projectTwo}
                    github="https://github.com/Saaweel/LaberintoJAVA"
                    languages="Java"
                />
                <ProjectsCard
                    title="LondonEye RP"
                    des="Servidor de Rol en FiveM para los mayores creadores de contenido de España y Latinoamérica."
                    src={projectThree}
                    youtube={"https://www.youtube.com/watch?v=4B0kHkHClrE"}
                    languages="Lua · SQL · HTML · CSS · JavaScript"
                />
                <ProjectsCard
                    title="Proyecto 176"
                    des="Servidor survival de FiveM con una temática post-apocalíptica con zombies radioactivos, creado para los mayores creadores de contenido de España y Latinoamérica."
                    src={projectFour}
                    languages="Lua · SQL · HTML · CSS · JavaScript"
                />
            </div>
        </section>
    );
};

export default Projects;