export const navLinksdata = [
    {
        _id: 1001,
        title: "Inicio",
        link: "home",
    },
    {
        _id: 1002,
        title: "Habilidades",
        link: "features",
    },
    {
        _id: 1003,
        title: "Proyectos",
        link: "projects",
    },
    {
        _id: 1004,
        title: "Resumen",
        link: "resume",
    },
    // {
    //     _id: 1005,
    //     title: "Testimonial",
    //     link: "testimonial",
    // },
    {
        _id: 1006,
        title: "Contacto",
        link: "contact",
    },
];
