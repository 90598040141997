import React from 'react';
import './Loading.css';
import { logo } from "../../assets/index";

const Loading = () => {
    return (
        <div className="loader-container bg-bodyColor">
            <img className="w-32" src={logo} alt="logo" />
        </div>
    );
};

export default Loading;