import React from "react";
// import { useTypewriter, Cursor } from "react-simple-typewriter";
import {
    FaTwitter,
    FaLinkedinIn,
    FaGithub,
    FaJs,
    FaNodeJs,
} from "react-icons/fa";
import { SiExpress, SiMongodb } from "react-icons/si";

const LeftBanner = () => {
    // const [text] = useTypewriter({
    //   words: ["Professional Coder.", "Full Stack Developer.", "UI Designer."],
    //   loop: true,
    //   typeSpeed: 20,
    //   deleteSpeed: 10,
    //   delaySpeed: 2000,
    // });
    return (
        <div className="w-full lgl:w-1/2 flex flex-col gap-20">
            <div className="flex flex-col gap-5">
                <h4 className=" text-lg font-normal">
                    ¡Bienvenido a mi sitio web!
                </h4>
                <h1 className="text-6xl font-bold text-white">
                    <span className="text-designColor capitalize">
                        Saúl Díaz (Saaweel)
                    </span>
                </h1>
                <h2 className="text-4xl font-bold text-white">
                    {/* a <span>{text}</span>
                    <Cursor
                        cursorBlinking="false"
                        cursorStyle="|"
                        cursorColor="#00bdb9"
                    /> */}
                    Backend Developer
                </h2>
                <p className="text-base font-bodyFont leading-6 tracking-wide">
                    Soy un desarrollador de servicios en línea con experiencia
                    en tecnologías como FiveM, Lua, JS, SQL, HTML y CSS. También
                    tengo conocimientos en Discord.js, NodeJS, Express y estoy
                    aprendiendo React. Mi objetivo es crear soluciones técnicas
                    eficientes y escalables para satisfacer las necesidades de
                    mis clientes.
                </p>
            </div>
            <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
                <div>
                    <h2 className="text-base uppercase font-titleFont mb-4">
                        Mis redes
                    </h2>
                    <div className="flex gap-4">
                        <a
                            rel="noreferrer"
                            href="https://twitter.com/saaweel"
                            target="_blank"
                        >
                            <span className="bannerIcon">
                                <FaTwitter />
                            </span>
                        </a>
                        <a
                            rel="noreferrer"
                            href="https://github.com/saaweel"
                            target="_blank"
                        >
                            <span className="bannerIcon">
                                <FaGithub />
                            </span>
                        </a>
                        <a
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/saúl-díaz-517672261/"
                            target="_blank"
                        >
                            <span className="bannerIcon">
                                <FaLinkedinIn />
                            </span>
                        </a>
                    </div>
                </div>
                <div>
                    <h2 className="text-base uppercase font-titleFont mb-4">
                        Intereses
                    </h2>
                    <div className="flex gap-4">
                        <span className="bannerIcon">
                            <FaJs />
                        </span>
                        <span className="bannerIcon">
                            <FaNodeJs />
                        </span>
                        <span className="bannerIcon">
                            <SiExpress />
                        </span>
                        <span className="bannerIcon">
                            <SiMongodb />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeftBanner;
