import React from "react";
import { motion } from "framer-motion";

const Skills = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-6"
        >
            <div className="w-full">
                <div className="py-12 font-titleFont flex flex-col gap-4">
                    <h2 className="text-3xl md:text-4xl font-bold">
                        Lenguajes usados
                    </h2>
                </div>
                <div className='className="mt-14 w-full flex flex-col gap-6'>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">Lua</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[90%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    90%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">JavaScript</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[80%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    80%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">SQL</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[60%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    60%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">CSS</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[35%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    35%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">HTML</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[30%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    30%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">Java</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[20%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    20%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">PHP</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[10%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    10%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                </div>
            </div>

            <div className="w-full">
                <div className="py-12 font-titleFont flex flex-col gap-4">
                    <h2 className="text-3xl md:text-4xl font-bold">
                        Tecnologías utilizadas
                    </h2>
                </div>
                <div className="flex flex-col gap-6">
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">FiveM</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[99%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    99%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">NodeJS</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[55%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    55%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">DiscordJS</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[45%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    45%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">Bootstrap / SB-Admin 2</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[40%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    40%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">Express</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[25%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    25%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">MongoDB</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[20%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    20%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">React</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[15%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    15%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                    <div className="overflow-x-hidden">
                        <p className="text-sm uppercase font-medium">Nginx</p>
                        <span className="w-full h-2 bgOpacity rounded-md inline-flex mt-2">
                            <motion.span
                                initial={{ x: "-100%", opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                                className="w-[13%] h-full bg-gradient-to-r from-blue-600 to-green-500 rounded-md relative"
                            >
                                <span className="absolute -top-7 right-0">
                                    10%
                                </span>
                            </motion.span>
                        </span>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Skills;
