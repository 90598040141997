import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            className="py-12 font-titleFont flex gap-20"
        >
            <div className="w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
                <ResumeCard
                    title="Técnico en Desarrollo de Aplicaciones Multiplataforma"
                    subTitle="IES Albarregas (Mérida, Spain) (2022 - Actualidad)"
                    result="9.4/10"
                />
                <ResumeCard
                    title="Técnico en Instalaciones de Telecomunicaciones"
                    subTitle="IES Emérita Augusta (Mérida, Spain) (2020 - 2022)"
                    result="8.1/10"
                />
                <ResumeCard
                    title="Educación Secundaria"
                    subTitle="IES Extremadura (Montijo, Spain) (2016 - 2020)"
                    result="6.4/10"
                />
            </div>
        </motion.div>
    );
};

export default Education;
