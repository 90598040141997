import React from "react";
import { AiFillAppstore } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import { SiProgress } from "react-icons/si";
import Title from "../layouts/Title";
import Card from "./Card";

const Features = () => {
    return (
        <section
            id="features"
            className="w-full py-20 border-b-[1px] border-b-black"
        >
            <Title title="Habilidades" des="Mi trabajo" />
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
                <Card
                    title="Bots para Discord"
                    des="Creación y despliegue de bots para la plataforma Discord utilizando Node.js, Discord.js entre otras herramientas."
                    icon={<FaDiscord />}
                />
                <Card
                    title="Paneles de gestión"
                    des="Crear paneles de gestión personalizados y escalables para empresas utilizando tecnologías como Node.js, Express o MongoDB."
                    icon={<AiFillAppstore />}
                />
                <Card
                    title="Webs"
                    des="Creación, despliegue y mantenimiento de páginas webs para sitios modernos y rápidos."
                    icon={<SiProgress />}
                />
            </div>
        </section>
    );
};

export default Features;
