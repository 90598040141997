import React, { useState, useEffect } from "react";
import Loading from "./components/loading/Loading";
import Banner from "./components/banner/Banner";
import Contact from "./components/contact/Contact";
import Features from "./components/features/Features";
// import Footer from "./components/footer/Footer";
import FooterBottom from "./components/footer/FooterBottom";
import Navbar from "./components/navbar/Navbar";
import Projects from "./components/projects/Projects";
import Resume from "./components/resume/Resume";
// import Testimonial from "./components/tesimonial/Testimonial";
import FadeInOut from "./FadeInOut";

function App() {
    const [show, setShow] = useState(true);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setDuration(500);
            setShow(false);
        }, 1000);
    }, []);

    return (
        <div className="w-full h-auto bg-bodyColor text-lightText px-4">
            <FadeInOut show={show} duration={duration}>
                <Loading />
            </FadeInOut>
            <Navbar />
            <div className="max-w-screen-xl mx-auto">
                <Banner />
                <Features />
                <Projects />
                <Resume />
                {/* <Testimonial /> */}
                <Contact />
                {/* <Footer /> */}
                <FooterBottom />
            </div>
        </div>
    );
}

export default App;