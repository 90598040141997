import React, { useState } from "react";
import Title from "../layouts/Title";
import Education from "./Education";
import Skills from "./Skills";
import Experience from "./Experience";

const Resume = () => {
    const [educationData, setEducationData] = useState(true);
    const [skillData, setSkillData] = useState(false);
    const [experienceData, setExperienceData] = useState(false);
    return (
        <section
            id="resume"
            className="w-full py-20 border-b-[1px] border-b-black"
        >
            <div className="flex justify-center items-center text-center">
                <Title title="+3 Años de experiencia" des="Resumen" />
            </div>
            <div>
                <ul className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                    <li
                        onClick={() =>
                            setEducationData(true) &
                            setSkillData(false) &
                            setExperienceData(false)
                        }
                        className={`${
                            educationData
                                ? "border-designColor rounded-lg"
                                : "border-transparent"
                        } resumeLi`}
                    >
                        Estudios
                    </li>
                    <li
                        onClick={() =>
                            setEducationData(false) &
                            setSkillData(true) &
                            setExperienceData(false)
                        }
                        className={`${
                            skillData
                                ? "border-designColor rounded-lg"
                                : "border-transparent"
                        } resumeLi`}
                    >
                        Habilidades profesionales
                    </li>
                    <li
                        onClick={() =>
                            setEducationData(false) &
                            setSkillData(false) &
                            setExperienceData(true)
                        }
                        className={`${
                            experienceData
                                ? "border-designColor rounded-lg"
                                : "border-transparent"
                        } resumeLi`}
                    >
                        Experiencia laboral
                    </li>
                </ul>
            </div>
            {educationData && <Education />}
            {skillData && <Skills />}
            {experienceData && <Experience />}
        </section>
    );
};

export default Resume;
