import React from "react";
import { FaGlobe, FaTiktok, FaTwitter } from "react-icons/fa";

const ResumeCard = ({ title, subTitle, result, des, twitter, tiktok, website }) => {
    return (
        <div style={{"minHeight": "33.33%"}} className="w-full group flex">
            <div className="w-10 h-[6px] bgOpacity mt-16 relative">
                <span className="absolute w-5 h-5 rounded-full -top-2 -left-3 flex justify-center items-center bg-black bg-opacity-60">
                    <span className="w-3 h-3 rounded-full bg-bodyColor inline-flex group-hover:bg-designColor duration-300"></span>
                </span>
            </div>
            <div className="w-full bg-black bg-opacity-20 hover:bg-opacity-30 duration-300  rounded-lg p-4 lgl:px-10 flex flex-col justify-center gap-6 lgl:gap-10 shadow-shadowOne">
                <div className="flex flex-col lgl:flex-row justify-between gap-4 lgl:gap-0 lgl:items-center">
                    <div>
                        <h3 className="text-xl md:text-2xl font-semibold group-hover:text-white duration-300">
                            {title}
                        </h3>
                        <p className="text-sm mt-2 text-gray-400 group-hover:text-white duration-300">
                            {subTitle}
                        </p>
                    </div>
                    <div>
                        <p className="px-4 py-2 text-designColor bg-black bg-opacity-25 rounded-lg flex justify-center items-center shadow-shadowOne text-sm font-medium">
                            {result}
                        </p>
                    </div>
                </div>
                {des ? (
                    <p className="text-sm md:text-base font-medium text-gray-400 group-hover:text-gray-300 duration-300">
                        {des}
                    </p>
                ) : (
                    ""
                )}
                <div>
                    {twitter ? (
                        <a
                            href={twitter}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span className="mr-2 text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer">
                                <FaTwitter />
                            </span>
                        </a>
                    ) : (
                        ""
                    )}
                    {tiktok ? (
                        <a
                            href={tiktok}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span className="mr-2 text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer">
                                <FaTiktok />
                            </span>
                        </a>
                    ) : (
                        ""
                    )}
                    {website ? (
                        <a
                            href={website}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span className="mr-2 text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer">
                                <FaGlobe />
                            </span>
                        </a>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResumeCard;
