import React, { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import Title from "../layouts/Title";
import ContactLeft from "./ContactLeft";

const Contact = () => {
    const [username, setUsername] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const form = useRef();

    // ========== Email Validation start here ==============
    const emailValidation = () => {
        return String(email)
            .toLocaleLowerCase()
            .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
    };
    // ========== Email Validation end here ================

    const handleSend = (e) => {
        e.preventDefault();
        if (username === "") {
            setErrMsg("Debes introducir tu nombre");
        // } else if (phoneNumber === "") {
        //     setErrMsg("Debes introducir tu número de teléfono");
        } else if (email === "") {
            setErrMsg("Debes introducir tu email");
        } else if (!emailValidation(email)) {
            setErrMsg("Debes introducir un email válido");
        } else if (subject === "") {
            setErrMsg("Debes introducir un asunto");
        } else if (message === "") {
            setErrMsg("Debes introducir un mensaje");
        } else {
            emailjs.sendForm('service_c34e1z5', 'template_zkwiw5h', form.current, 'uzmULuOQoiXqd_uz2')
            .then((result) => {
                setSuccessMsg(
                    `Gracias ${username}, tu mensaje ha sido enviado correctamente.`
                );
                setErrMsg("");
                setUsername("");
                setPhoneNumber("");
                setEmail("");
                setSubject("");
                setMessage("");
            }, (error) => {
                setErrMsg("Ha ocurrido un error insesperado, puedes mandar el correo manualmente a: sauldiazdiaz210@gmail.com");
            });
        }
    };
    return (
        <section
            id="contact"
            className="w-full py-20 border-b-[1px] border-b-black"
        >
            <div className="flex justify-center items-center text-center">
                <Title title="Contacto" des="Contacta conmigo" />
            </div>
            <div className="w-full">
                <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
                    <ContactLeft />
                    <div className="w-full lgl:w-[60%] h-full py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b] flex flex-col gap-8 p-4 lgl:p-8 rounded-lg shadow-shadowOne">
                        <form ref={form} onSubmit={handleSend} className="w-full flex flex-col gap-4 lgl:gap-6 py-2 lgl:py-5">
                            {errMsg && (
                                <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-orange-500 text-base tracking-wide animate-bounce">
                                    {errMsg}
                                </p>
                            )}
                            {successMsg && (
                                <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-green-500 text-base tracking-wide animate-bounce">
                                    {successMsg}
                                </p>
                            )}
                            <div className="w-full flex flex-col lgl:flex-row gap-10">
                                <div className="w-full lgl:w-1/2 flex flex-col gap-4">
                                    <p className="text-sm text-gray-400 uppercase tracking-wide">
                                        Nombre
                                    </p>
                                    <input
                                        onChange={(e) =>
                                            setUsername(e.target.value)
                                        }
                                        value={username}
                                        className="contactInput"
                                        type="text"
                                        name="user_name"
                                    />
                                </div>
                                <div className="w-full lgl:w-1/2 flex flex-col gap-4">
                                    <p className="text-sm text-gray-400 uppercase tracking-wide">
                                        Teléfono
                                    </p>
                                    <input
                                        onChange={(e) =>
                                            setPhoneNumber(e.target.value)
                                        }
                                        value={phoneNumber}
                                        className="contactInput"
                                        type="text"
                                        name="user_phone"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-4">
                                <p className="text-sm text-gray-400 uppercase tracking-wide">
                                    Email
                                </p>
                                <input
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    className="contactInput"
                                    type="email"
                                    name="user_email"
                                />
                            </div>
                            <div className="flex flex-col gap-4">
                                <p className="text-sm text-gray-400 uppercase tracking-wide">
                                    Asunto
                                </p>
                                <input
                                    onChange={(e) => setSubject(e.target.value)}
                                    value={subject}
                                    className="contactInput"
                                    type="text"
                                    name="subject"
                                />
                            </div>
                            <div className="flex flex-col gap-4">
                                <p className="text-sm text-gray-400 uppercase tracking-wide">
                                    Mensaje
                                </p>
                                <textarea
                                    onChange={(e) => setMessage(e.target.value)}
                                    value={message}
                                    className="contactTextArea"
                                    cols="30"
                                    rows="8"
                                    name="message"
                                ></textarea>
                            </div>
                            <div className="w-full">
                                <input
                                    type="submit" value="Enviar mensaje"
                                    className="w-full h-12 bg-[#141518] rounded-lg text-base text-gray-400 tracking-wider uppercase hover:text-white duration-300 hover:border-[1px] hover:border-designColor border-transparent"
                                />
                            </div>
                            {errMsg && (
                                <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-orange-500 text-base tracking-wide animate-bounce">
                                    {errMsg}
                                </p>
                            )}
                            {successMsg && (
                                <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-green-500 text-base tracking-wide animate-bounce">
                                    {successMsg}
                                </p>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
