import React from "react";
import { FaGithub, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { contactImg } from "../../assets/index";

const ContactLeft = () => {
    return (
        <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
            <img
                className="w-full h-64 object-cover rounded-lg mb-2"
                src={contactImg}
                alt="contactImg"
            />
            <div className="flex flex-col gap-4">
                <h3 className="text-3xl font-bold text-white">Saúl Díaz (Saaweel)</h3>
                <p className="text-lg font-normal text-gray-400">
                    Backend Developer
                </p>
                <p className="text-base text-gray-400 flex items-center gap-2">
                    Email:{" "}
                    <span className="text-lightText">
                        sauldiazdiaz210@gmail.com
                    </span>
                </p>
            </div>
            <div className="flex flex-col gap-4">
                <h2 className="text-base uppercase font-titleFont mb-4">
                    Redes sociales
                </h2>
                <div className="flex gap-4">
                    <a
                        rel="noreferrer"
                        href="https://twitter.com/saaweel"
                        target="_blank"
                    >
                        <span className="bannerIcon">
                            <FaTwitter />
                        </span>
                    </a>
                    <a
                        rel="noreferrer"
                        href="https://github.com/saaweel"
                        target="_blank"
                    >
                        <span className="bannerIcon">
                            <FaGithub />
                        </span>
                    </a>
                    <a
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/saúl-díaz-517672261/"
                        target="_blank"
                    >
                        <span className="bannerIcon">
                            <FaLinkedinIn />
                        </span>
                    </a>
                </div>
            </div>
            <a onClick={window.gtag("event", "show_cv")} href="/Saul_Diaz_CV.pdf" target="_blank">
                <button className="w-full h-12 bg-[#141518] rounded-lg text-base text-gray-400 tracking-wider uppercase hover:text-white duration-300 hover:border-[1px] hover:border-designColor border-transparent">
                    Ver CV
                </button>
            </a>
        </div>
    );
};

export default ContactLeft;