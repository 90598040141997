import React from "react";

const FooterBottom = () => {
    return (
        <div className="w-full py-10">
            <p className="text-center text-gray-500 text-base">
                © 2023 - 2024 All Rights Reserved / Thanks to Noor Mohammad for the help.
            </p>
        </div>
    );
};

export default FooterBottom;